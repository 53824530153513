import newcarLogo from '@/newcar/ui/common/img/logo/logo_newcar.svg';
import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';

import HeaderMegaMenu from '@/newcar/ui/common/molecule/HeaderMegaMenu';

import * as styles from './Header.module.styl';

interface State {
  isMenuActive: boolean
}

type HeaderProps = {
  h1Text?: string;
}

const Header: FC<HeaderProps> = ({ children, h1Text }) => {
  const [state, setState] = useState<State>({
    isMenuActive: false,
  });

  const toggleMenu = (): void => {
    const { isMenuActive } = state;
    setState({
      isMenuActive: !isMenuActive,
    });
  };

  return (
    <header className="is-full-width no_fixed is-zindex-menu">
      <nav
        className={`${styles.container} container navbar is-zindex-menu`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/" className="is-flex has_vertical_centered">
              <div className="">
                <img
                  className="is-full-width"
                  src={newcarLogo}
                  alt={h1Text || 'おトクにマイカー 定額カルモくん'}
                />
              </div>
            </Link>
          </div>
          <div
            onClick={toggleMenu}
            role="button"
            className={`navbar-burger burger navbtn ${
              state.isMenuActive ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarManus"
            tabIndex={0}
            id="nvbtn"
            data-gtm="gtm-newcar-navbar"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>
        <div
          id="navbarManus"
          className={`navbar-menu ${state.isMenuActive ? 'is-active' : ''}`}
        >
          <div className="navbar-start" />
          <div className="navbar-end">
            {/* PCメニュー */}
            <HeaderMegaMenu />

            {/* SPメニュー */}
            <div className="navbar-item is-hoverable has-dropdown has-text-black-ter">
              <div className={`${styles.navbar_dropdown} navbar-dropdown`}>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.TOP} data-gtm="gtm-newcar-navbar-top">
                  TOP
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.FIRST_GUIDE} data-gtm="gtm-newcar-navbar-first-guide">
                  はじめての方へ
                </Link>

                {/* サービス内容 */}
                <details className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>サービス内容</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.ABOUT_CARMO} data-gtm="gtm-newcar-navbar-about">
                      定額カルモくんとは？
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.FEE} data-gtm="gtm-newcar-navbar-fee">
                      料金について
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.MAINTENANCE} data-gtm="gtm-newcar-navbar-maintenance">
                      メンテナンスプラン
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.MORAERU} data-gtm="gtm-newcar-navbar-moraeru">
                      もらえるオプション
                    </Link>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      to={NEWCAR_DEFINE.PATH.CAR_INSURANCE}
                      data-gtm="gtm-newcar-navbar-carinsurance"
                    >
                      自動車保険
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.CONTRACT_FLOW} data-gtm="gtm-newcar-navbar-docs-contract">
                      ご納車までのながれ
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.STAFF} data-gtm="gtm-newcar-navbar-staff">
                      スタッフ紹介
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.USERVOICE} data-gtm="gtm-newcar-navbar-uservoice">
                      お客様の声
                    </Link>
                  </div>
                </details>
                <details className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>新車を探す</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP} data-gtm="gtm-newcar-navbar-newcar-search">
                      新車一覧から探す
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE1} data-gtm="gtm-newcar-navbar-newcar-search-price1">
                      1万円台のおクルマ
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE2} data-gtm="gtm-newcar-navbar-newcar-search-price2">
                      2万円台のおクルマ
                    </Link>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP_PRICE3} data-gtm="gtm-newcar-navbar-newcar-search-price3">
                      3万円台のおクルマ
                    </Link>
                  </div>
                </details>

                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.USEDCAR_TOP} data-gtm="gtm-newcar-navbar-usedcar-search">
                  中古車を探す
                </Link>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.CAMPAIGN_NOW} data-gtm="gtm-newcar-navbar-campaigns">
                  キャンペーン
                </Link>
                <details className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>ご契約中の方</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <Link className={`${styles.navbar_icon} ${styles.navbar_item_inner}`} to={NEWCAR_DEFINE.PATH.APP_REASSURANCE} data-gtm="gtm-newcar-navbar-newcar-app">
                      定額カルモくんアプリ
                    </Link>
                  </div>
                </details>
                <Link className={`${styles.navbar_icon} ${styles.navbar_item} navbar-item`} to={NEWCAR_DEFINE.PATH.INFORMATION} data-gtm="gtm-newcar-navbar-information">
                  お知らせ一覧
                </Link>

                <details className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>運営会社</summary>
                  <div className={`${styles.navbar_item_container}`}>
                    <a
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner} navbar-item`}
                      href={NEWCAR_DEFINE.PATH.COMPANY_ABOUT}
                      data-gtm="gtm-newcar-navbar-docs-company"
                      target="_blank"
                      rel="noreferrer"
                    >
                      会社概要
                    </a>
                    <a
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner} navbar-item`}
                      href={NEWCAR_DEFINE.PATH.MEDIA}
                      data-gtm="gtm-newcar-navbar-column-media"
                      target="_blank"
                      rel="noreferrer"
                    >
                      メディア掲載情報
                    </a>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner} navbar-item`}
                      to={NEWCAR_DEFINE.PATH.SOCIAL_CONTRIBUTIONS}
                      data-gtm="gtm-newcar-navbar-social-contributions"
                    >
                      社会貢献活動
                    </Link>
                  </div>
                </details>

                <span className="border" />
                {/* よくあるご質問・お問合せ・資料請求 */}
                <details className={`${styles.accordion_nav}`}>
                  <summary className={`${styles.navbar_button}`}>よくあるご質問・お問合せ・資料請求</summary>
                  <div className={`${styles.navbar_item_container} ${styles.navbar_item_container_qa}`}>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      to={NEWCAR_DEFINE.PATH.FAQ}
                      data-gtm="gtm-newcar-navbar-faq"
                    >
                      よくあるご質問
                    </Link>
                    <a
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      href={NEWCAR_DEFINE.PATH.NURTURING_LINK}
                      data-gtm="gtm-newcar-navbar-nurturing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      資料請求
                    </a>
                    <Link
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      to={NEWCAR_DEFINE.PATH.CONTACT}
                      data-gtm="gtm-newcar-navbar-contact"
                    >
                      お問合せ
                    </Link>
                    <a
                      className={`${styles.navbar_icon} ${styles.navbar_item_inner}`}
                      href={NEWCAR_DEFINE.PATH.CORPORATE_GOOGLE_FORM}
                      data-gtm="gtm-newcar-navbar-corporate-form"
                      target="_blank"
                      rel="noreferrer"
                    >
                      法人・個人事業主のお客様
                    </a>
                  </div>
                </details>
                <div className={`${styles.navbar_simu_button}`}>
                  <Link className={`${styles.navbar_icon}`} to={NEWCAR_DEFINE.PATH.SIMULATION_ROUTING} data-gtm="gtm-newcar-navbar-simulation">
                    シミュレーション結果を見る
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {children}
    </header>
  );
};

export default Header;
